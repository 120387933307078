@tailwind base;
@tailwind components;
@tailwind utilities;

/* apply some general styles */
@layer base {
  body {
    @apply font-secondary text-lg text-dark  bg-[#015445];
  }
  .section {
    @apply py-[40px] lg:py-[160px];
  }
  .link {
    @apply uppercase font-primary tracking-[0.08em];
  }
  .h1 {
    @apply text-[60px] lg:text-[118px] tracking-[-2px] leading-none font-tertiary mb-4;
  }
  .h2 {
    @apply text-[40px] xl:text-[65px] text-dark uppercase font-primary leading-none mb-[38px];
  }
  /* buttons */
  .btn {
    @apply font-primary text-lg tracking-[0.08em] uppercase px-[40px] transition-all duration-300 flex items-center gap-x-[10px];
  }
  .btn-sm {
    @apply h-[55px];
  }
  .btn-lg {
    @apply h-[67px];
  }
  .btn-outline {
    @apply border border-white/70 bg-none hover:bg-white hover:text-dark;
  }
  .btn-dark {
    @apply bg-dark hover:bg-dark/90 transition-all text-white;
  }
  .btn-link {
    @apply px-0 h-auto text-dark hover:text-dark/90 transition-all;
  }
  .hero-p {
    text-shadow: 1px 1px 2px rgb(43, 43, 43);
    font-weight: 500;
  }
  .shadow {
    text-shadow: 1px 1px 2px rgb(43, 43, 43);
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
